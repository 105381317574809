import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./fetch";
import { serverConfig } from "../../config";
import { Org } from "../../types/org";
import { ApiResponse } from "../../types/response";
import createQueryParams from "../../utils/createQueryParams";
import { Setting } from "../../types/setting";
import {PatchOrgSubscriptionDto} from "@illumix-inc/types.apollo-monorepo/types/libs/core/src";

const TAG = "Orgs";
const LIST = "LIST";

export const orgsApi = createApi({
  reducerPath: "orgsApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${serverConfig.url}/${serverConfig.endpoint_prefix}/${serverConfig.version}/`,
  }),
  tagTypes: [TAG],
  endpoints: (builder) => ({
    getOrgById: builder.query<
      ApiResponse<Org, { settings?: Setting }>,
      { id: string; include?: ("settings")[] }
    >({
      query: ({ id, include = [] }) => ({
        url: `orgs/${id}${createQueryParams({ include: include.join(",") })}`,
        method: "GET",
      }),
      providesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: result.data.id }] : [],
    }),

    getOrgs: builder.query<ApiResponse<Org[]>, void>({
      query: () => ({ url: `orgs`, method: "GET" }),
      providesTags: (result, err) =>
        result?.data != null
          ? [
              { type: TAG, id: LIST },
              ...result.data.map((i) => ({ type: TAG, id: i.id } as const)),
            ]
          : [],
    }),

    createOrg: builder.mutation<
      ApiResponse<Org>,
      { data: Omit<Org,  "id" | "createdAt" | "updatedAt"> }
    >({
      query: ({ data }) => ({
        url: `orgs`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),

    updateOrg: builder.mutation<
      ApiResponse<Org>,
      { id: string; data: Partial<Org> }
    >({
      query: ({ id, data }) => ({
        url: `orgs/${id}`,
        method: "PUT",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
              { type: TAG, id: LIST },
              { type: TAG, id: result.data.id },
            ]
          : [],
    }),

    updateSubscriptions: builder.mutation<
      ApiResponse<Org>,
      { id: string; data: PatchOrgSubscriptionDto }
    >({
      query: ({ id, data }) => ({
        url: `orgs/${id}/subscriptions`,
        method: "PATCH",
        data: data,
      }),
      invalidatesTags: (result, err) =>
        result?.data
          ? [
            { type: TAG, id: LIST },
            { type: TAG, id: result.data.id },
          ]
          : [],
    }),

    deleteOrg: builder.mutation<ApiResponse<Org>, { id: string }>({
      query: ({ id }) => ({
        url: `orgs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, err) =>
        result?.data ? [{ type: TAG, id: LIST }] : [],
    }),
  }),
});

export const {
  useGetOrgByIdQuery,
  useGetOrgsQuery,
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useDeleteOrgMutation,
  useUpdateSubscriptionsMutation,
} = orgsApi;
