import "react-perfect-scrollbar/dist/css/styles.css";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import { auth0Config } from "./config";
import { PermissionsProvider } from "./hooks/contexts/PermissionsContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { AuthProvider } from "./hooks/contexts/AuthContext";
import {SnackbarProvider} from "notistack";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
require('react-big-calendar/lib/css/react-big-calendar.css');
require('./style/styles.css');

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Auth0Provider
              domain={auth0Config.domain}
              clientId={auth0Config.client_id}
              audience={auth0Config.audience}
              redirectUri={window.location.origin}
              cacheLocation={"localstorage"}
            >
              <AuthProvider>
                <BrowserRouter>
                  <PermissionsProvider>
                    <SnackbarProvider
                        preventDuplicate
                        dense
                        maxSnack={5}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                    >
                      <App />
                    </SnackbarProvider>
                  </PermissionsProvider>
                </BrowserRouter>
              </AuthProvider>
            </Auth0Provider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
