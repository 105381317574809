import { ProjectOption } from "./types/org";
import { UserRole } from "./types/permissions";

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};

export const DASHBOARD_CHANGED_ROLES: UserRole[] = [
  "owner",
  "member",
  "viewer",
];

export const PROJECT_OPTIONS_MAPPING: Record<ProjectOption, string> = {
  "try_on:rings": "Rings",
  "try_on:vehicles": "Vehicles",
  "try_on:glasses": "Glasses",
  "try_on:furnitures": "Furniture",
  "try_on:luggages": "Luggages",
  "try_on:purses": "Purses",
  "try_on:necklaces": "Necklaces",
  "try_on:image_tracking": "Image Tracking",
  venue: "Venue",
  venue_v2: "Venue V2"
};

export const CREATE_PROJECT_HASH = '#createProject';