import {FC, PropsWithChildren, useEffect} from "react";
import {useParams} from "react-router";
import {Outlet, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {useGetUserByIdQuery} from "../store/services/users";
import {useGetOrgByIdQuery} from "../store/services/orgs";
import {ApiResponseError} from "../types/response";
import SplashScreen from "./SplashScreen";

/*
* Checks if org is valid and routes accordingly
* IMPORTANT: assumes user is valid and exists
* */
const OrgFetchWrapper: FC<PropsWithChildren> = (
  props
) => {
  const { paramOrgId } = useParams();
  const navigate = useNavigate();
  const { userId } = useAuth();
  
  const { data: userData, error: userErr } = useGetUserByIdQuery({
    id: userId || '',
    include: ["permissions"],
  });
  
  const { data: org, error: orgErr } = useGetOrgByIdQuery({
    id: paramOrgId || '',
    include: ["settings"],
  });
  
  useEffect(() => {
    // handle org error
    if (orgErr != null) {
      let err = orgErr as ApiResponseError;
      navigate(
        `/${err.status && typeof err.status === "number" ? err.status : 500}`
      );
    }
  }, [navigate, orgErr, org, userData, userErr, paramOrgId]);
  
  return (
    <>
      {org?.data ? <><Outlet /></> : <SplashScreen />}
    </>
  );
};

export default OrgFetchWrapper;